import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import TextContainer from '../components/TextContainer'
import PageBody from '../components/PageBody'
import GreenLine from '../components/GreenLine'
import SEO from '../components/SEO'

export const query = graphql`
  query {
    allContentfulGenerelleTexte(
      filter: { visible: { eq: true }, category: { eq: "Kontakt" } }
      sort: { order: ASC, fields: order }
    ) {
      edges {
        node {
          id
          category
          order
          json {
            facebook {
              icon
              text
              url
            }
            youtube {
              icon
              text
              url
            }
            linkedin {
              icon
              text
              url
            }
          }
          text {
            json
          }
          title
        }
      }
    }
    contentfulPage(slug: { eq: "kontakt" }) {
      id
      title
      subtitle
      headerImage {
        id
        fluid(quality: 100, maxWidth: 1920, resizingBehavior: NO_CHANGE, toFormat: NO_CHANGE) {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
          ...GatsbyContentfulFluid
        }
      }
      body {
        json
      }
    }
  }
`

const Kontakt = ({ data }) => {
  const postNode = {
    title: `${data.contentfulPage.title} - ${config.siteTitle}`,
  }

  const FlexBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    div {
      flex: 1 0 33%;
      &:nth-child(4n-4) {
        flex: 1 0 100%;
      }
      margin: 0;
      height: auto;
      background-color: transparent;
      font-family: ${props => props.theme.fonts.title};
      margin-bottom: 2rem;

      @media screen and (max-width: ${props => props.theme.responsive.medium}) {
        flex: 1 0 45%;
      }

      @media screen and (max-width: ${props => props.theme.responsive.small}) {
        flex: 1 0 100%;
      }

      h3 {
        font-size: 1.4em;
        line-height: 1.25;
        font-weight: 800;
        margin-bottom: 1rem;
        color: ${props => props.theme.colors.highlight};
      }
      p {
        line-height: 1.25;
        margin: 0;
        color: ${props => props.theme.colors.secondary};
      }
      a {
        color: ${props => props.theme.colors.highlight};
        transition: color 0.3s;
        -moz-transition: color 0.3s;
        -webkit-transition: color 0.3s;
        color: ${props => props.theme.colors.highlight};
        border: none !important;
        &:hover {
          background-color: transparent !important;
          color: ${props => props.theme.colors.secondary};
        }
      }
    }
  `

  const { body, title, subtitle } = data.contentfulPage

  const mappedDivs = data.allContentfulGenerelleTexte.edges.map((el, i) => (
    <div key={`general-text-${i}`}>
      <h3>{el.node.title}</h3>
      {el.node.text && <PageBody body={el.node.text} />}
      {el.node.json && (
        <div>
          <p>
            <a
              href={el.node.json.facebook.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className={`ui icon ${el.node.json.facebook.icon}`} />
              {' '}
              {el.node.json.facebook.text}
            </a>
          </p>
          <p>
            <a
              href={el.node.json.linkedin.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className={`ui icon ${el.node.json.linkedin.icon}`} />
              {' '}
              {el.node.json.linkedin.text}
            </a>
          </p>
          <p>
            <a
              href={el.node.json.youtube.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className={`ui icon ${el.node.json.youtube.icon}`} />
              {' '}
              {el.node.json.youtube.text}
            </a>
          </p>
        </div>
      )}
    </div>
  ))

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="/kontakt" customTitle />

      <GreenLine backgroundColor="rgba(0,0,0,0)" />

      <Container>
        <TextContainer>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </TextContainer>

        <Container>
          <TextContainer>
            <FlexBox>{mappedDivs}</FlexBox>
          </TextContainer>
        </Container>

        {body && <PageBody body={body} />}
      </Container>
    </Layout>
  )
}

export default Kontakt
